import React, { lazy } from "react";
import { Navigate } from 'react-router-dom'
import { rootStore } from '~/store'
// const NotFound = lazy(() => import("~/layouts/NotFind"));
const Buy = lazy(() => import("~/views/buy"));
const Login = lazy(() => import("~/views/login"));
const Forgot = lazy(() => import("~/views/forgot"));
const SignUp = lazy(() => import("~/views/signUp"));
const Purchase = lazy(() => import("~/components/category/purchase"))

const storeKeys = Object.keys(rootStore.category.sectionServers)
const paths = storeKeys.map((s, i) => {
  const buyList = new Set()
  rootStore.category.sectionServers[s].list.forEach((e) => {
    e.list.forEach(b => {
      buyList.add(b)
    })
  })
  return {
    path: i + '-' + rootStore.category.sectionServers[s].title,
    element: <Buy />,
    children: Array.from(buyList).map((b, i) => {
      return {
        path: `purchase/${i + 1}`,
        element: <Buy />
      }
    })
  }
})
const routes = [
  {
    path: '/',
    element: <Navigate to="/store" />
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/store",
    element: <Buy />,
    children: [...paths]
  },
  {
    path: '/forgot',
    element: <Forgot />
  },
  {
    path: '/sign-up',
    element: <SignUp />
  },
];

export default routes;
