import { useRoutes } from 'react-router-dom';
import routes from "~/router";
import Footer from './components/footer/footer';
import Header from './components/header/header';
function App() {
  return (
    <div className="flex flex-col grow">
      <Header />
      <div className='h-20'></div>
      <div className='grow'>
        {useRoutes(routes)}
      </div>
      <Footer />
    </div>
  );
}

export default App;
