import React from "react";

const Footer = () => {
	return (
		<footer className="w-full p-10 lg:p-10 bg-[rgb(20,4,30)] h-[295px] flex justify-center">
			<div className="w-[1152px] text-footColor flex flex-col justify-between">
				<p className="text-[21px] font-bold text-white">4299云计算</p>
				<p className="text-[13px]">成都安刻网络科技有限公司</p>
				<p className="text-[13px]">工信部备案号： 蜀ICP备2022018392号-4</p>
				<p className="text-[13px]">公安部备案号：川公网安备 51019002005850号</p>
				<span className="bg-footColor w-[19px] h-0.5 inline-block"></span>
				<div>
					<p className="text-xs">Powered by Hostbee.</p>
					<p className="text-xs">Copyright © 2024. All rights reserved.</p>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
