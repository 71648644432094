import React from "react";
import { Link } from "react-router-dom";

function Header() {
	return (
		<header className="px-32 h-20 flex justify-between items-center fixed top-0 w-full bg-white">
			<h1 className="font-bold text-[21px] font-inter">4299云计算</h1>
			<div className="px-4">
				<Link to="store" className="text-base pl-1 pr-9 font-medium">
					购买产品
				</Link>
				<Link to="login" className="text-base pl-1 pr-9 font-medium">
					登录/注册
				</Link>
			</div>
		</header>
	);
}

export default Header;
