
import Category from "./category";
import { createContext } from "react";

class RootStore {
  constructor() {
    this.category = new Category()
  }
}

const rootStore = new RootStore()

const RootStoreContext = createContext()

export { rootStore, RootStoreContext }
