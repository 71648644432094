import { makeAutoObservable } from 'mobx'

class Category {
  shanghai = {
    title: '上海 - 高性能云服务器',
    list: [
      {
        entrance: '跨境通 - 上海B区 - BGP入口',
        list: [{
          name: '跨境通 - 上海 B - B1000',
          infor: '稳定低jitter，BGP入口全国延迟优秀，北京联通至入口仅30ms，武汉联通至入口仅16ms。',
          detail: ['百度智能云上海区域BGP多线入口独立 IP 1 个',
            '日本东京 BGP 出口独立 IP 1 个',
            '2 vCPU',
            '2 GB 运行内存',
            '每月双向流量 1000 GB',
            '端口速率 150Mbps'
          ],
          above: '限量现货，购买后请联系企业微信客服获取入口公网IP。',
          price: 408
        }, {
          name: '跨境通 - 上海 B - B500',
          infor: '稳定低jitter，BGP入口全国延迟优秀，北京联通至入口仅30ms，武汉联通至入口仅16ms。',
          detail: ['百度智能云上海区域BGP多线入口独立 IP 1 个',
            '日本东京 BGP 出口独立 IP 1 个',
            '2 vCPU',
            '2 GB 运行内存',
            '每月双向流量 500 GB',
            '端口速率 100Mbps'
          ],
          above: '限量现货，购买后请联系企业微信客服获取入口公网IP。',
          price: 333
        }]
      }, {
        entrance: '跨境通 - 上海A区 - 移动入口',
        list: [{
          name: '跨境通 - 上海A - A1000',
          infor: '',
          detail: ['上海移动单线入口独立 IP 1 个',
            '日本东京 BGP 出口独立 IP 1 个',
            '2 vCPU',
            '2 GB 运行内存',
            '每月双向流量 1000 GB',
            '端口速率 150Mbps'
          ],
          above: '现货，付款后立即开通。',
          price: 280
        }, {
          name: '跨境通 - 上海A - A2000',
          infor: '',
          detail: ['上海移动单线入口独立 IP 1 个',
            '日本东京 BGP 出口独立 IP 1 个',
            '2 vCPU',
            '2 GB 运行内存',
            '每月双向流量 2000 GB',
            '端口速率 150Mbps'
          ],
          above: '现货，付款后立即开通。',
          price: 540
        }, {
          name: '跨境通 - 上海 - 500M',
          infor: '',
          detail: [],
          above: '',
          price: 16888
        }]
      }, {
        entrance: '跨境通 - 上海A区升级版 - 移动入口',
        list: [{
          name: '跨境通 - 上海A - AX1000',
          infor: '',
          detail: ['上海移动单线入口独立 IP 1 个',
            '日本东京 BGP 出口独立 IP 1 个',
            '4 vCPU',
            '4 GB 运行内存',
            '每月双向流量 1000 GB + <strong>额外赠送 200GB</strong>',
            '<strong>端口速率升级至 500Mbps</strong>'
          ],
          above: '现货，付款后立即开通。',
          price: 560
        }, {
          name: '跨境通 - 上海A - AX2000',
          infor: '',
          detail: ['上海移动单线入口独立 IP 1 个',
            '日本东京 BGP 出口独立 IP 1 个',
            '4 vCPU',
            '4 GB 运行内存',
            '每月双向流量 2000 GB + <strong>额外赠送 500GB</strong>',
            '<strong>端口速率升级至 500Mbps</strong>'
          ],
          above: '现货，付款后立即开通。',
          price: 980
        }]
      }]
  }
  chengdu = {
    title: '成都 - 高性能云服务器',
    list: [{
      entrance: '游戏云 - 成都电信A区',
      list: [{
        name: '游戏云 - 成都A1',
        infor: '4C8G',
        detail: ['100M@1000G共享带宽@流量',
          '高性价比云服务器'
        ],
        above: '',
        price: 40
      }, {
        name: '游戏云 - 成都A2',
        infor: '8C16G',
        detail: ['100M@2000G共享带宽@流量',
          '高性价比云服务器'
        ],
        above: '',
        price: 180
      }]
    }]
  }
  anshan = {
    title: '鞍山 - 高性能云服务器',
    list: [{
      entrance: '游戏云 - 鞍山电信A区',
      list: [{
        name: '游戏云 - 鞍山A1',
        infor: '鞍山GOLD高性能游戏服务器',
        detail: [],
        above: '',
        price: 220
      }, {
        name: '游戏云 - 鞍山A0',
        infor: '鞍山GOLD高性能游戏服务器',
        detail: [],
        above: '',
        price: 220
      }, {
        name: '游戏云 - 鞍山A2',
        infor: '鞍山GOLD高性能游戏服务器',
        detail: [],
        above: '',
        price: 400
      }, {
        name: '游戏云 - 鞍山A3',
        infor: '鞍山GOLD高性能游戏服务器',
        detail: [],
        above: '',
        price: 220
      }, {
        name: '游戏云 - 鞍山A2-B',
        infor: '鞍山GOLD高性能游戏服务器',
        detail: [],
        above: '',
        price: 400
      }, {
        name: '游戏云 - 鞍山B0',
        infor: '鞍山GOLD高性能游戏服务器',
        detail: [],
        above: '',
        price: 150
      }]
    }]
  }
  guangzhou = {
    title: '广州 - 高性能云服务器',
    list: [{
      entrance: '跨境通 - 广州移动A区',
      list: [{
        name: '跨境通 - 广州A - A1000',
        infor: '',
        detail: [
          '广州移动单线入口独立 IP 1 个',
          '中国香港 BGP 出口独立 IP 1 个',
          '2 vCPU',
          '2 GB 运行内存',
          '20GB 储存空间',
          '每月双向流量 1000 GB',
          '端口速率 100Mbps'
        ],
        above: '',
        price: 210
      }, {
        name: '跨境通 - 广州A - A2000',
        infor: '鞍山GOLD高性能游戏服务器',
        detail: [
          '广州移动单线入口独立 IP 1 个',
          '中国香港 BGP 出口独立 IP 1 个',
          '2 vCPU',
          '2 GB 运行内存',
          '20GB 储存空间',
          '每月双向流量 2000 GB',
          '端口速率 150Mbps'
        ],
        above: '现货，付款后立即开通',
        price: 400
      }]
    }]
  }
  current = 'shanghai'
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true, name: '分类' })
  }
  get sectionServers() {
    return {
      shanghai: this.shanghai,
      chengdu: this.chengdu,
      anshan: this.anshan,
      guangzhou: this.guangzhou
    }
  }
  changeCurrent(n) {
    this.current = n
  }
}

export default Category